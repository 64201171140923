@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');
:root {
  /* --background-color: #2196f3; */
  --water-color: #2196f3;
  --alt-bkg-color: #badbef;
  --swe-color-primary: #fff;
  --swe-color-light: #222;
  --hotel-dist: 6rem;
}




@media (min-width: 200px) {
  .container {
    max-width: 360px;
  }
  .sizzle-icon {
    display: none;
  }
  .nav-menu {
    min-width: 100vw;
  }
  .venue-hotel-info-right {
    padding: 0px 20px 0px 3em;
  }
  .sub-page-title {
    font-size: 1.5em;
  }
  .block-one-text {
    flex-direction: column;
  }
  .block-availability {
    display: block;
  }
  .sub-button {
    flex-direction: column;
  }
  .secondary-nav {
    margin-top: calc(var(--navbar-height) - 0px);
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
  .sizzle-icon {
    display: block;
  }
  .sub-page-title {
    font-size: 2em;
  }
  .secondary-nav {
    margin-top: calc(var(--navbar-height) - 4px);
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
  .nav-menu {
    min-width: 768px;
  }
  .venue-hotel-info-right {
    padding: 0px 50px 0px 4em;
  }
  .sub-page-title {
    font-size: 3em;
  }
  .block-one-text {
    flex-direction: row;
  }
  .block-availability {
    display: inline;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

/* @media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
  .sub-button {
    flex-direction: row;
  }
} */

footer {
  margin-top: 20px;
  padding: 20px 10px;
  border-top: 1px solid #ddd;

  p {
    display: flex;
    justify-content: flex-end;
  }
}

.button-homepage {
  margin: 10px 20px;
}